<template>
  <div v-loading="loading">
    <CCard>
      <CCardHeader>
        <strong>{{ $t('cloud.title.group') }}</strong>
      </CCardHeader>
      <CCardBody>
        <el-form label-width="25%" @submit.native.prevent>
          <el-form-item :label="$t('cloud.title.group_name')" class="mb-1">
            <el-input :placeholder="$t('cloud.title.group_name_ph')" v-model="group.name" clearable style="width:240px;" />
          </el-form-item>
          <el-form-item :label="$t('cloud.title.note')">
            <el-input type="textarea" :placeholder="$t('cloud.title.lpr_group_memo_ph')" v-model="group.memo" :autosize="{ minRows:3, maxRows:3 }" resize="none" style="width:400px;" @input="onMemo" />
          </el-form-item>
        </el-form>
      </CCardBody>
      <CCardFooter>
        <CRow class="justify-content-end mx-4">
          <CButton class="mx-1" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
          <CButton class="mx-1" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  props: {
    initData: {type: Object}
  },
  data() {
    return {
      loading: false,
      group: {
        id: null,
        name: null,
        memo: "",
        lps: {
          list:[]
        }
      },
      new_lps:[]
    }
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  mounted() {
    this.fetchInitData();
  },
  methods: {
    fetchInitData() {
      if (this.initData) {
        this.getData();
      }
    },
    getData() {
      axios.get(`/api/lpr/eu/group/${this.initData.id}/`)
        .then(result => {
          console.log(result.data);
          this.group = JSON.parse(JSON.stringify(result.data));
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      if (!this.group.name) {
        this.$notify.warning({
          title: this.$t('msgbox.confirm.title'),
          message: this.$t('msgbox.validate.name'),
          offset: 30
        });
        return;
      }
      this.$confirm(this.$t('msgbox.confirm.content'), this.$t('msgbox.confirm.title'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$emit('payload', this.group);
      })
    },
    onMemo() {
      const max_bytes = 250;
      let byte_num = this.$utility.checkBytes(this.group.memo);
      while(byte_num > max_bytes) {
        this.group.memo = this.group.memo.substring(0, this.group.memo.length-1);
        byte_num = this.$utility.checkBytes(this.group.memo);
      }
    }
  }
}
</script>